@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Quicksand', sans-serif;
    }
}

@layer components {

    /* The container */
    .custome-checkbox {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .custome-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .custome-checkmark {
        position: absolute;
        top: -10px;
        left: -10px;
        height: 20px;
        width: 20px;
        border: 2px solid #c4b5fd;
        border-radius: 3px;
    }

    /* On mouse-over, add a grey background color */
    .custome-checkbox:hover input~.custome-checkmark {
        background-color: #ddd6fe;
    }

    /* When the checkbox is checked, add a blue background */
    .custome-checkbox input:checked~.custome-checkmark {
        background-color: #ddd6fe;
    }

    /* Create the custome-checkmark/indicator (hidden when not checked) */
    .custome-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the custome-checkmark when checked */
    .custome-checkbox input:checked~.custome-checkmark:after {
        display: block;
    }

    /* Style the custome-checkmark/indicator */
    .custome-checkbox .custome-checkmark:after {
        left: 5px;
        top: 2px;
        width: 6px;
        height: 10px;
        border: solid #6d28d9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}